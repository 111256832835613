import { defineStore } from 'pinia'
import { useRouter } from 'vue-router' // Ensure this import is present

export const useConfig = defineStore('config', () => {
  const config = ref(null)

  function updateConfig(_config) {
    const userApi = useUser()
    const { userProfile } = useKeycloak()
    config.value = _config
    userApi.updateUserConfig({
      userId: userProfile.UserId,
      config: config.value,
    })
  }

  async function fetchConfig() {
    const userApi = useUser()
    const { $features } = useNuxtApp()
    const { $posthog } = useNuxtApp()
    const { userProfile } = useKeycloak()
    const router = useRouter()

    try {
      let data = await userApi.fetchUserConfig({
        userId: userProfile.UserId,
      })

      if (data?.noEntry && parseInt(userProfile.UserId) > 318151) {
        config.value = {}
        $posthog.onFeatureFlags(() => {
          if (
            $posthog.isFeatureEnabled('welcome-flow')
          ) {
            router.push('/welcome')
          }
        })
      } else {
        config.value = data?.config
      }
    } catch (e) {
      config.value = {}
    }
  }

  return {
    config,
    updateConfig,
    fetchConfig,
  }
})
